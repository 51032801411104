<template>
  <div class="wrapper">
    <header-component></header-component>
    <div class="container-xxl">
      <div class="container">
        <router-view />
      </div>
    </div>
    <!-- <div class="line-container">
      <Line :top="'10'" :transform="'-30deg'" />
    </div> -->
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import Line from "@/components/Line.vue";
import { useHead } from "@vueuse/head";

useHead({
  title: "Леон - Салон реконструкции волос",
  meta: [
    {
      name: "description",
      content:
        'Леон - салон реконструкции волос в Кургане. Услуги: Холодное восстановление, Кератин/Ботокс, Тотальное восстановление, Комплексная стрижка, Комплекс "Супер шелк", Нанопластика. Дополнительные услуги: Пиллинг, Полировка, Подложка, Густота.',
    },
    {
      name: "keywords",
      content:
        "Леон, салон реконструкции волос, холодное восстановление, кератин, ботокс, тотальное восстановление, стрижка, супер шелк, нанопластика, пиллинг, полировка, подложка, густота",
    },
    {
      name: "author",
      content: "Леон",
    },
    {
      property: "og:title",
      content: "Леон - Салон Реконструкции Волос",
    },
    {
      property: "og:description",
      content:
        'Леон - салон реконструкции волос в Кургане. Услуги: Холодное восстановление, Кератин/Ботокс, Тотальное восстановление, Комплексная стрижка, Комплекс "Супер шелк", Нанопластика. Дополнительные услуги: Пиллинг, Полировка, Подложка, Густота.',
    },
    {
      property: "og:image",
      content: "https://leon-studio.ru/favicon.ico",
    },
    {
      property: "og:url",
      content: "https://leon-studio.ru",
    },
    {
      property: "og:type",
      content: "website",
    },
  ],
  script: [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        name: "Леон - Салон реконструкции волос",
        image: "https://leon-studio.ru/favicon.ico",
        "@id": "https://leon-studio.ru",
        url: "https://leon-studio.ru",
        telephone: "+7-919-576-00-69",
        address: {
          "@type": "PostalAddress",
          streetAddress: "ул. Ленина 39",
          addressLocality: "Курган",
          addressRegion: "Курганская область",
          postalCode: "640000",
          addressCountry: "RU",
        },
        description:
          "Леон - салон реконструкции волос в Кургане. Услуги: Холодное восстановление, Кератин/Ботокс, Тотальное восстановление, Комплексная стрижка, Комплекс 'Супер шелк', Нанопластика. Дополнительные услуги: Пиллинг, Полировка, Подложка, Густота.",
        openingHours: ["Mo-Su 10:00-22:00"],
        sameAs: [
          "https://www.facebook.com/leon-studio",
          "https://www.instagram.com/leon-studio",
        ],
      },
    },
  ],
});

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Line,
  },
};
</script>

<style>
*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Poiret One";
  src: url("../src/assets/fonts/PoiretOne-Regular.ttf");
}

html,
body {
  height: 100%;
  font-family: "Poiret One";
}

body {
  background-color: #1a1a1a;
  color: #ffffff;
  line-height: 22px;
  font-weight: 400;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

.container-xxl {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  /* max-width: 1200px; Убедитесь, что контейнер использует всю доступную ширину */
  width: 100%; /* Добавьте это, чтобы элементы внутри могли занимать всю ширину контейнера */
  margin: 0 auto;
  /* padding: 0 10px; */
  flex-grow: 1;
}

.container > * {
  width: 100%; /* Элементы внутри контейнера будут занимать всю доступную ширину */
  box-sizing: border-box; /* Padding и border не будут влиять на ширину */
}

/* Ссылки */
.link {
  text-decoration: none;
  color: #ffffff;
}

/* Блок сайта */
.block-site {
  padding-top: 150px;
  position: relative;
}

/* Заголовок блока сайта */
.title-site {
  margin-left: 220px;
  font-size: 64px;
  color: #f4bc83;
  margin-bottom: 50px;
}

.line-container {
  position: relative;
}

/* Стили для скроллбара */
::-webkit-scrollbar {
  width: 10px; /* Ширина скроллбара */
}

/* Стили для фона скроллбара */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Стили для ползунка скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #f4bc83; /* Цвет ползунка */
  border-radius: 10px; /* Закругленные углы */
  border: 3px solid #000; /* Отступы, чтобы создать эффект тонкого скроллбара */
}

.router-link {
  text-decoration: none;
  color: white;
}

/* input */
.form__group {
  position: relative;
  padding: 15px 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1em;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1em;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #f4bc83, #f4bc83);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #f4bc83;
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.input-group {
  margin: 20px 0;
}

@media screen and (max-width: 480px) {
  .title-site {
    margin-left: 10px;
    font-size: 40px;
  }
  .block-site {
    padding-top: 0;
  }

  .p-tabview-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .p-tabview-header {
    margin: 20px;
  }
}

@media screen and (max-width: 375px) {
  .block-site {
    margin-top: 80px;
  }
}

@media screen and (max-width: 320px) {
  .p-tabview {
    max-width: 320px;
  }
}

/* Настройка вкладок */
.p-tabview-nav {
  display: flex;
  justify-content: center;
}

.p-tabview-header {
  list-style-type: none;
  margin: 20px;
  font-size: 26px;
  cursor: pointer;
}

.p-tabview-ink-bar {
  display: none;
}

.p-tabview-panels {
  display: grid;
  justify-items: center;
}

.p-tabview .p-tabview-nav {
  background: none;
}

#pv_id_4_2_header_action,
#pv_id_27_0_header_action {
  border: none;
  background: none;
  color: #f4bc83;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: none;
  border: none;
  color: #f4bc83;
  font-family: "Poiret One";
}

.p-tabview .p-tabview-panels {
  background: none;
  padding: 0;
  border: 0 none;
  color: white;
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}

.p-tabview-nav {
  border: none;
}

.p-calendar {
  width: 300px;
}

.p-inputnumber-input {
  max-width: 80px !important;
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.0625em #f4bc83;
  border-color: #f4bc83;
}

a {
  color: #f4bc83;
}

.p-button {
  color: #ffffff;
  background: #f4bc83;
  border: 1px solid #aa835c;

  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
  outline-color: transparent;
}

.p-inputtext {
  max-width: 120px;
}

.p-inputotp-input {
  width: 40px;
  height: 40px;
}

.p-inputotp-input:hover {
  border-color: #f4bc83;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #fad5af;
  border-color: #f4bc83;
}

/* .p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.p-link {
  background: transparent;
  border: none;
  color: #f4bc83;
  font-size: 22px;
}

.p-link > svg {
  width: 20px;
  height: 20px;
}

.p-calendar > input {
  padding: 7px 50px;
} */
</style>
