import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Calendar from "primevue/calendar"; // импортируем компонент Calendar
import "primevue/resources/themes/saga-blue/theme.css"; // тема для PrimeVue
import "primevue/resources/primevue.min.css"; // основные стили
import "primeicons/primeicons.css"; // иконки PrimeVue

const app = createApp(App);
const head = createHead();

const ru = {
  firstDayOfWeek: 1,
  dayNames: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthNamesShort: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
  today: "Сегодня",
  clear: "Очистить",
  weekHeader: "Нед",
};

app
  .use(store)
  .use(router)
  .use(PrimeVue, {
    locale: ru, // Передаем объект локализации в конфигурацию PrimeVue
  })
  .component("Calendar", Calendar) // регистрируем компонент Calendar
  .use(head)
  .mount("#app");
