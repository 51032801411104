<template>
  <div class="create">
    <div class="card">
      <div class="filter-title">Выберите дату</div>
      <Calendar
        v-model="selectedDate"
        selectionMode="single"
        :minDate="minDate"
        :manualInput="false"
        dateFormat="yy-mm-dd"
        inline
        @change="getAppointment"
      />
    </div>
    <div class="card">
      <div class="filter-title">Записи</div>
      <div class="all-appointments" v-if="appointments.length > 0">
        <div
          class="appointment"
          v-for="appointment in appointments"
          :key="appointment.id"
        >
          <div class="appointment-text">
            <DateTimeFormatterVue
              :date="appointment.date"
              v-if="appointments.length > 0"
            />
          </div>
          <div class="appointment-text">
            {{ appointment.time.slice(0, 5) }}
          </div>
          <div class="appointment-text">
            {{ appointment.masterDtoSmall.firstName }}
            {{ appointment.masterDtoSmall.lastName }}
          </div>
        </div>
      </div>
      <div class="all-appointments" v-else>
        <div class="appointment-text">Записи отсутствует</div>
      </div>
    </div>
    <div class="card">
      <div class="filter-title">Выберите время</div>
      <InputNumber
        v-model="hours"
        :min="0"
        :max="23"
        size="2"
        hideButton
        class="time-input"
      />
      :
      <InputNumber
        v-model="minutes"
        :min="0"
        :max="59"
        size="2"
        hideButton
        class="time-input"
      />
    </div>
    <div class="card">
      <div class="filter-title">Данные клиента</div>
      <form @submit.prevent="submitForm">
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Имя"
            required
            v-model="firstName"
            id="firstName"
          />
          <label for="firstName" class="form__label">Имя</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Фамилия"
            v-model="lastName"
            id="lastName"
          />
          <label for="lastName" class="form__label">Фамилия</label>
        </div>
        <div class="form__group field">
          <InputMask
            type="tel"
            class="form__field phone"
            placeholder="Телефон"
            required
            v-model="phone"
            id="phone"
            mask="+79999999999"
          />
          <label for="phone" class="form__label">Телефон</label>
        </div>
        <div class="input-group">
          <select id="service" v-model="service" required>
            <option disabled value="">Выберите услугу</option>
            <option
              v-for="service in services"
              :key="service.id"
              :value="service.name"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
        <div class="input-group" v-if="this.role[0].authority == 'ADMIN'">
          <select id="master" v-model="masterId" required>
            <option
              v-for="master in masters"
              :key="master.id"
              :value="master.id"
            >
              {{ master.firstName }} {{ master.lastName }}
            </option>
          </select>
        </div>
        <ButtonComponent :name="'Сохранить'" :type="button" />
      </form>
    </div>
    <ModalInfo
      v-if="isModalVisible"
      :message="modalMessage"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "primevue/calendar";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask";
import { jwtDecode } from "jwt-decode";
import DateTimeFormatterVue from "@/components/DateTimeFormatter.vue";
import ModalInfo from "@/components/ModalInfo.vue";

import "primevue/resources/themes/saga-blue/theme.css"; // тема
import "primevue/resources/primevue.min.css"; // основные стили
import "primeicons/primeicons.css"; // иконки

export default {
  components: {
    Calendar,
    ButtonComponent,
    InputNumber,
    InputMask,
    DateTimeFormatterVue,
    ModalInfo,
  },
  data() {
    return {
      selectedDate: null,
      minDate: new Date(),
      hours: "12",
      minutes: "00",
      masters: [],
      masterId: null,
      role: null,
      services: [],
      service: null,
      firstName: "",
      lastName: "",
      phone: "",
      appointments: [],
      isModalVisible: false,
      modalMessage: "",
    };
  },
  watch: {
    selectedDate: {
      immediate: false,
      handler(newDate) {
        if (newDate) {
          this.getAppointment();
        } else {
          this.appointments = [];
        }
      },
    },
  },
  methods: {
    getMasters() {
      axios
        .get("https://leon-back-hiss.amvera.io/masters")
        .then((response) => {
          this.masters = response.data;
        })
        .catch((error) => console.log(error));
    },
    getService() {
      axios
        .get("https://leon-back-hiss.amvera.io/service")
        .then((response) => {
          this.services = response.data;
        })
        .catch((error) => console.log(error));
    },
    getAppointment() {
      const formattedDate = this.selectedDate.toLocaleDateString("ru-RU");
      console.log(formattedDate);

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(
          "https://leon-back-hiss.amvera.io/appointment/date/dto?date=" +
            formattedDate,
          {
            headers,
          }
        )
        .then((response) => {
          this.appointments = response.data;
          //   console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      const year = this.selectedDate.getFullYear();
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, "0"); // Добавляем 1, так как месяцы начинаются с 0
      const day = String(this.selectedDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      const formattedTime = `${this.hours
        .toString()
        .padStart(2, "0")}:${this.minutes.toString().padStart(2, "0")}`;

      const data = {
        date: formattedDate,
        time: formattedTime,
        clientName: `${this.firstName} ${this.lastName}`,
        service: this.service,
        clientPhone: this.phone,
        master: {
          id: this.masterId,
        },
      };

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .post("https://leon-back-hiss.amvera.io/appointment", data, {
          headers,
        })
        .then((response) => {
          console.log("Запись успешно создана", response);
          this.showModal("Запись успешно создана");
        })
        .catch((error) => {
          console.error("Ошибка при создании записи", error);
          this.showModal("Произошла ошибка при создании записи");
        });
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
        this.modalMessage = "";
      }, 2000);
    },
  },
  mounted() {
    this.getMasters();
    this.getService();
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      this.role = decodedToken.role;
    }
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-title {
  font-size: 22px;
  color: #f4bc83;
  margin-bottom: 20px;
  font-weight: bold;
}

.p-calendar {
  width: 100%;
}

.form__group {
  position: relative;
  padding: 15px 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1em;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1em;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #f4bc83, #f4bc83);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #f4bc83;
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.phone {
  max-width: 100%;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  appearance: none;
}

select:focus {
  border-color: #f4bc83;
  outline: none;
}

option {
  background-color: #2a2a2a;
  color: #fff;
}

option:checked,
option:hover {
  background-color: #f4bc83;
}

.appointment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.appointment-text {
  margin-right: 5px;
}

@media screen and (max-width: 320px) {
  .card {
    max-width: 300px;
  }
}
</style>
